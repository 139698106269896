<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <pie-chart
            v-if="pieChartData"
            :data="pieChartData"
            :options="pieChartOptions"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <bar-chart
            v-if="barChartData"
            :chartData="barChartData"
            :options="barChartOptions"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <bar-chart
            v-if="bar2ChartData"
            :chartData="bar2ChartData"
            :options="bar2ChartOptions"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
           v-model="bar3date"
           :items="bar3dates"
           label="Месяц"
          />

          <bar-chart
            v-if="bar3ChartData"
            :chartData="bar3ChartData"
            :options="bar3ChartOptions"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { VerificationsService } from "@/common/api.service"
import PieChart from "@/components/Charts/Pie.vue";
import BarChart from "@/components/Charts/Bar.vue";

export default {
  components: {
    PieChart,
    BarChart
  },

  data () {
    return {
      pie: [],
      bar: [],
      bar2: [],
      bar3: [],
      bar3date: '',
      bar3dates: [],
      pieChartOptions: {},
      barChartOptions: { responsive: true, maintainAspectRatio: false, scales: { yAxes: [{ ticks: { beginAtZero: true, precision:0 }}]}},
      bar2ChartOptions: { responsive: true, maintainAspectRatio: false, scales: { yAxes: [{ ticks: { beginAtZero: true, precision:0 }}]}},
      bar3ChartOptions: { /*tooltips: { enabled: false },*/ responsive: true, maintainAspectRatio: false, scales: { xAxes: [{ stacked: true }], yAxes: [{ ticks: { beginAtZero: true, precision:0 }}]}},
    }
  },

  computed: {
    pieChartData () {
      if (this.pie.length === 0) {
        return null
      }

      const statuses = [0, 0, 0, 0, 0, 0, 0, 0]

      this.pie.forEach(elem => {
        statuses[elem.meter_status_id - 1] = elem.total
      })

      return {
        labels: ["Ожидание", "В работе", "Выполнено", "Отказ", "Незавершён", "Согласование", "Не согласован", "Согласовано"],
        datasets: [
          {
            label: "Месяц",
            backgroundColor: ["#BCAAA4", "#FFE0B2", "#C8E6C9", "#757575", "#FFCDD2", "#FFCDD2", "#FFCDD2", "#FFCDD2"],
            data: statuses
          }
        ]
      }
    },

    barChartData () {
      if (this.bar.length === 0) {
        return null
      }

      const labels = []
      const data = []

      let date = new Date();

      date.setMonth(date.getMonth() - 12)

      for (let i = 0; i < 12; i++) {
        date.setMonth(date.getMonth() + 1)

        labels.push(this.getMonth(date.getMonth()))

        const item = this.bar.find((item) => item.month === (date.getMonth() + 1))

        data.push(item ? item.total : 0)
      }

      return {
        labels: labels,
        datasets: [
          {
            label: "Всего поверок",
            backgroundColor: '#1E88E5',
            data: data
          }
        ]
      }
    },

    bar2ChartData () {
      if (this.bar2.length === 0) {
        return null
      }

      const labels = []
      const data = []

      let date = new Date();

      date.setMonth(date.getMonth() - 12)

      for (let i = 0; i < 12; i++) {
        date.setMonth(date.getMonth() + 1)

        labels.push(this.getMonth(date.getMonth()))

        const item = this.bar2.find((item) => item.month === (date.getMonth() + 1))

        data.push(item ? item.total : 0)
      }

      return {
        labels: labels,
        datasets: [
          {
            label: "Всего поверок выполнено",
            backgroundColor: '#1E88E5',
            data: data
          }
        ]
      }
    },

    bar3ChartData () {
      if (this.bar3.length === 0 || this.bar3date.length === 0) {
        return null
      }

      const labels = []
      const data1 = []
      const data2 = []
      const data3 = []

      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.bar3[this.bar3date])) {
        labels.push(value.manager)
        data1.push(value.total)
        data2.push(value.done)
        data3.push(value.declined)
      }

      return {
        labels: labels,
        datasets: [
          {
            label: "Отменено",
            backgroundColor: '#E53935',
            data: data3
          },
          {
            label: "Выполнено",
            backgroundColor: '#43A047',
            data: data2
          },
          {
            label: "Всего заявок",
            backgroundColor: '#1E88E5',
            data: data1
          }
        ]
      }
    }
  },

  beforeMount () {
    VerificationsService
      .getReport()
      .then((response) => {
        this.pie = response.pie
        this.bar = response.bar
        this.bar2 = response.bar2
        this.bar3 = response.bar3

        this.bar3dates = Object.keys(this.bar3)
        this.bar3date = this.bar3dates[this.bar3dates.length - 1]
      })
  },

  watch: {
    bar3dates: {
      handler () {},
      deep: true
    }
  },

  methods: {
    getMonth (id) {
      const months = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ]

      return months[id]
    }
  }
}
</script>
